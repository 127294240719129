<template>
    <div>
        <v-menu offset-y content-class="menuClassAcciones">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    class="btnAcciones pr-1 mr-3"
                    :class="[btnColor]"
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                >
                    {{btnNombre}} 
                    <template  v-for="(item,valor) in attrs" >
                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#FFFFFF'>expand_less</v-icon>
                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#FFFFFF'>expand_more</v-icon>
                    </template>
                </v-btn>
                
            </template>
            <div class="btnAccionesContenedor">
                <slot name="opciones"></slot>
            </div>
        </v-menu>
    </div>
</template>
<script>
export default {
    props: {
        btnNombre:{
            type: String,
            default: "Submit"
        },
        btnColor:{
            type:String,
            default:"color-58C5D8"
        }

        
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    created() {
        
    },
    mounted() {
        
    },
    updated() {
        
    },
}
</script>
<style scoped>
    .color-58C5D8{
        background:#58C5D8 !important;
    }
    .color-1E2245{
        background: #1E2245 !important;
    }
    .menuClassAcciones{
        /* box-shadow: none; */
        border-radius: 10px!important;
        margin-top:15px;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: none !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAcciones:hover {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: none !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }
    .btnAccionesContenedor {
        width: 300px;
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: #FFF;
    }

</style>